import { FunctionComponent } from 'react';

const ShippingSvg: FunctionComponent<{
  title?: string;
  className?: string;
}> = ({ title, ...props }) => (
  <svg
    {...props}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M48.7598 1.82805C48.7402 1.73335 48.694 1.64623 48.6265 1.57694C48.559 1.50764 48.4732 1.45905 48.3791 1.43689C42.1043 -0.0977725 27.6093 5.37046 19.7479 13.2261C18.3468 14.6156 17.0693 16.1244 15.9297 17.7354C13.5066 17.5162 11.0834 17.7008 9.02027 18.6008C3.19434 21.1624 1.49929 27.8526 1.0262 30.7246C1.00057 30.882 1.01152 31.0432 1.0582 31.1956C1.10488 31.3481 1.18604 31.4877 1.29538 31.6038C1.40473 31.7198 1.53933 31.8091 1.68875 31.8648C1.83816 31.9204 1.99839 31.9409 2.157 31.9247L11.5115 30.8977C11.518 31.6033 11.5603 32.308 11.6384 33.0093C11.6872 33.494 11.9035 33.9465 12.25 34.289L15.872 37.911C16.2147 38.2577 16.6677 38.474 17.1528 38.5225C17.8498 38.6003 18.5504 38.6426 19.2517 38.6495L18.2259 47.9959C18.2099 48.1543 18.2305 48.3143 18.2862 48.4634C18.3419 48.6126 18.4311 48.747 18.547 48.8562C18.6628 48.9653 18.8023 49.0464 18.9545 49.0931C19.1067 49.1398 19.2676 49.1509 19.4248 49.1255C22.298 48.664 29.002 46.9689 31.5486 41.1441C32.4486 39.0787 32.6332 36.6683 32.4255 34.2566C34.0413 33.1181 35.5544 31.8405 36.9476 30.4385C44.8378 22.5921 50.2714 8.41554 48.7598 1.82805ZM29.9343 20.2739C29.2451 19.5849 28.7758 18.707 28.5856 17.7512C28.3954 16.7954 28.4929 15.8047 28.8658 14.9043C29.2386 14.004 29.8702 13.2344 30.6804 12.693C31.4907 12.1515 32.4433 11.8625 33.4179 11.8625C34.3924 11.8625 35.345 12.1515 36.1553 12.693C36.9656 13.2344 37.5971 14.004 37.97 14.9043C38.3429 15.8047 38.4404 16.7954 38.2502 17.7512C38.06 18.707 37.5906 19.5849 36.9014 20.2739C36.4443 20.732 35.9013 21.0955 35.3036 21.3434C34.7058 21.5914 34.065 21.719 33.4179 21.719C32.7707 21.719 32.1299 21.5914 31.5322 21.3434C30.9344 21.0955 30.3914 20.732 29.9343 20.2739V20.2739Z"
      stroke="#8BBE83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.12372 36.2356C6.97344 36.3768 5.9033 36.8983 5.08325 37.7172C3.05012 39.7572 2.85742 47.3128 2.85742 47.3128C2.85742 47.3128 10.4176 47.1201 12.4519 45.0847C13.2741 44.2659 13.7965 43.194 13.9347 42.0419"
      stroke="#8BBE83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShippingSvg;
