/* eslint-disable @next/next/no-img-element */
import cx from 'classnames';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';

import { useAppContext } from '../../providers/app-context';
import IconShape from '../../ui/icon-shape';

const LazyPicker = dynamic(
  () => import(/* webpackPreload: true */ '../../modules/home-picker'),
  {
    ssr: false,
  }
);

const Banner: FunctionComponent = () => {
  const { shippingLocation, isKBA, isPlate } = useAppContext();
  const { t } = useTranslation();
  const { locale = 'en' } = useRouter();

  const shippingLocationCountry = useMemo(() => {
    switch (shippingLocation) {
      case 1:
      case 2:
      case 3:
        return t('home:banner.countries.portugal');
      case 4:
      case 5:
      case 6:
        return t('home:banner.countries.spain');
      case 11:
        return t('home:banner.countries.france');
      case 13:
        return t('home:banner.countries.germany');
      case 23:
        return t('home:banner.countries.italy');
      case 25:
        return t('home:banner.countries.belgium');
      case 26:
        return t('home:banner.countries.austria');
      default:
        return t('home:banner.countries.european');
    }
  }, [shippingLocation, t]);

  const boxPlateHeight = useMemo(() => {
    if (isKBA || isPlate) {
      return 'h-[401px] lg:h-[398px]';
    } else {
      return 'h-[295px] lg:h-[292px]';
    }
  }, [isKBA, isPlate]);

  return (
    <section
      className={cx(
        'relative flex gap-4',
        'text-white',
        'pt-3 px-6 pb-6 lg:py-12',
        'before:absolute',
        'before:top-0',
        'before:left-0',
        'before:z-10',
        'before:w-full',
        'before:h-full',
        'before:bg-neutral-80/30'
      )}
    >
      <picture
        className={cx(
          'absolute',
          'top-0',
          'right-0',
          'bottom-0',
          'left-0',
          'overflow-hidden'
        )}
      >
        <source
          srcSet={`${process.env.PUBLIC_PREFIX}/img/banner-min.avif`}
          type="image/avif"
        />
        <source
          srcSet={`${process.env.PUBLIC_PREFIX}/img/banner-min.webp`}
          type="image/webp"
        />
        <source
          srcSet={`${process.env.PUBLIC_PREFIX}/img/banner-min.jp2`}
          type="image/jp2"
        />
        <img
          src={`${process.env.PUBLIC_PREFIX}/img/banner-min.jpeg`}
          alt={t('home:banner.imgAlt')}
          className={cx(
            'absolute',
            'h-full',
            'lg:h-auto',
            'max-w-none',
            'lg:max-w-full',
            'transform',
            '-translate-x-1/2',
            'min-w-full',
            'left-1/2'
          )}
        />
      </picture>
      <div
        className={cx(
          'lg:container mx-auto',
          'relative',
          'lg:max-w-5xl',
          'z-10',
          'lg:grid',
          'lg:grid-cols-2 lg:gap-4'
        )}
      >
        <div className="lg:self-center">
          <h1
            className={cx(
              'pb-3 lg:pb-0',
              '[text-shadow:0_2px_2px_rgba(0,0,0,0.75)]',
              'text-[30px] leading-[1.4em] lg:text-[40px]',
              'text-center lg:text-left'
            )}
          >
            <Trans
              i18nKey="home:banner.title"
              components={{ b: <b /> }}
              values={{ country: shippingLocationCountry }}
            />
          </h1>
          <ul
            className={cx(
              'hidden',
              'lg:grid lg:grid-rows-[repeat(3,48px)] gap-3',
              'mt-6'
            )}
          >
            <li className="flex gap-4 items-center">
              <IconShape
                isAbsolute={false}
                iconSrc={`${process.env.PUBLIC_PREFIX}/svg/icons/car-white.svg`}
                color="text-bparts-100"
              />
              <p className="text-bold ">
                <Trans
                  i18nKey="home:banner.bullets.stock"
                  components={{ b: <b /> }}
                />
              </p>
            </li>
            <li className="flex gap-4 items-center">
              <IconShape
                isAbsolute={false}
                iconSrc={`${process.env.PUBLIC_PREFIX}/svg/icons/fast-shipping-white.svg`}
                color="text-bparts-100"
              />
              <p>
                <Trans
                  i18nKey="home:banner.bullets.shipping"
                  components={{ b: <b /> }}
                />
              </p>
            </li>
            <li className="flex gap-4 items-center">
              <IconShape
                isAbsolute={false}
                iconSrc={`${process.env.PUBLIC_PREFIX}/svg/icons/shield-white.svg`}
                color="text-bparts-100"
              />
              <p>
                <Trans
                  i18nKey="home:banner.bullets.warranty"
                  components={{ b: <b /> }}
                />
              </p>
            </li>
          </ul>
        </div>
        <div className={boxPlateHeight}>
          <LazyPicker />
        </div>
      </div>
    </section>
  );
};

export default Banner;
