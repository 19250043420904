import cx from 'classnames';
import Trans from 'next-translate/Trans';
import { FunctionComponent } from 'react';

import { IconStar, IconHalfStar } from '../../ui/icons/start';
import BPImage from '../../ui/image';

import classes from './trustpilot.module.css';

const Trustpilot: FunctionComponent<{ className?: string | undefined }> = ({
  className,
}) => {
  const count = '+8000';
  const value = 4.8;

  return (
    <>
      <div
        className={cx(
          'flex',
          'bg-neutral-10',
          'justify-center',
          'items-center',
          'tracking-wider',
          className
        )}
      >
        <span className={'font-bold mx-1'}>{value}</span>
        <div className={cx('flex', classes.starsContainer)}>
          {[0, 1, 2, 3].map((_, index) => (
            <IconStar
              // eslint-disable-next-line react/no-array-index-key
              key={`google-star${index}`}
              className={cx(
                'h-4',
                'mr-1',
                value >= index + 1 ? 'text-bparts-100' : 'text-white'
              )}
            />
          ))}
          <IconHalfStar className={cx('h-4', 'mr-1', 'text-bparts-100')} />
        </div>
        <Trans
          i18nKey={'common:google-reviews'}
          components={{ strong: <strong className={'mx-1'} /> }}
          values={{
            count: count,
          }}
        />
        <span className={'hidden lg:block'}>Google Reviews</span>
        <BPImage
          src={`${process.env.PUBLIC_PREFIX}/svg/icons/google.svg`}
          className={'mx-1 h-6'}
          height={24}
          width={32}
          layout="fixed"
          alt="Google Reviews"
        />
      </div>
    </>
  );
};

export default Trustpilot;
