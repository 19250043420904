import { ParsedUrlQuery } from 'querystring';

import { locales } from '../../../../config/i18n';
import routesDA from '../../../../locales/da/routes.json';
import routesDE from '../../../../locales/de/routes.json';
import routesEN from '../../../../locales/en/routes.json';
import routesES from '../../../../locales/es/routes.json';
import routesFR from '../../../../locales/fr/routes.json';
import routesIT from '../../../../locales/it/routes.json';
import routesNB from '../../../../locales/nb/routes.json';
import routesNL from '../../../../locales/nl/routes.json';
import routesPT from '../../../../locales/pt/routes.json';
import { originalRoutes } from '../../../hooks/use-link-props';

const enUS = {
  contacts: '/en-us/contacts',
  'legal-mentions': '/en-us/legal-mentions',
  'terms-and-conditions': '/en-us/terms-and-conditions',
  'about-us': '/en-us/about-us',
  brands: '/en-us/auto-parts/makes',
  categories: '/en-us/auto-parts/categories',
  index: '/en-us',
  faqs: '/en-us/faqs',
  login: '/en-us/login',
  signup: '/en-us/signup',
  'password-reset': '/en-us/password-reset',
};

const replaceParams = (route: string, params?: { [key: string]: string }) => {
  if (!params) return route;

  return Object.entries(params).reduce((acc, [key, value]) => {
    return acc.replace(`[${key}]`, value);
  }, route);
};

const removeTrailingSlash = (route: string) => {
  if (route[route.length - 1] === '/') {
    return route.substring(0, route.length - 1);
  }

  return route;
};

const useHreflang = ({
  locale,
  route,
  params,
  translatedSlugs,
}: {
  locale: string;
  route: keyof typeof originalRoutes;
  params?: ParsedUrlQuery;
  translatedSlugs?: {
    da: { slug_type: string; slug_origin: string; slug_trans: string }[];
    de: { slug_type: string; slug_origin: string; slug_trans: string }[];
    en: { slug_type: string; slug_origin: string; slug_trans: string }[];
    es: { slug_type: string; slug_origin: string; slug_trans: string }[];
    fr: { slug_type: string; slug_origin: string; slug_trans: string }[];
    it: { slug_type: string; slug_origin: string; slug_trans: string }[];
    nb: { slug_type: string; slug_origin: string; slug_trans: string }[];
    nl: { slug_type: string; slug_origin: string; slug_trans: string }[];
    pt: { slug_type: string; slug_origin: string; slug_trans: string }[];
  };
}) => {
  const routes = {
    'x-default': routesEN[route],
    da: routesDA[route],
    de: routesDE[route],
    en: routesEN[route],
    'en-gb': routesEN[route],
    es: routesES[route],
    fr: routesFR[route],
    it: routesIT[route],
    nb: routesNB[route],
    nl: routesNL[route],
    pt: routesPT[route],
  };

  const links = ([...locales, 'en-gb'] as Array<Langs & 'en-gb'>).map(
    (locale) => {
      const localefinal = locale === 'en-gb' ? 'en' : locale;
      const domain =
        locale === 'en-gb'
          ? process.env.NEXT_PUBLIC_HOST?.includes('dev')
            ? 'https://dev.b-parts.co.uk'
            : 'https://www.b-parts.co.uk'
          : process.env.NEXT_PUBLIC_HOST?.includes('dev')
          ? 'https://dev.b-parts.com'
          : 'https://www.b-parts.com';

      return (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={`${domain}${removeTrailingSlash(
            replaceParams(
              routes[localefinal],
              params
                ? Object.entries(params).reduce((final, [query, slug]) => {
                    if (
                      translatedSlugs &&
                      translatedSlugs[localefinal] &&
                      translatedSlugs[localefinal].find(
                        (e) => e.slug_origin === slug
                      )
                    ) {
                      const newSlug = translatedSlugs[localefinal].find(
                        (e) => e.slug_origin === slug
                      );

                      return { ...final, [query]: newSlug?.slug_trans ?? slug };
                    }

                    return { ...final, [query]: slug };
                  }, {})
                : {}
            )
          )}`}
        />
      );
    }
  );

  links.push(
    <link
      key="x-default"
      rel="alternate"
      hrefLang="x-default"
      href={`${
        process.env.NEXT_PUBLIC_HOST?.includes('dev')
          ? 'https://dev.b-parts.com'
          : 'https://www.b-parts.com'
      }${removeTrailingSlash(
        replaceParams(
          routes['en'],
          params
            ? Object.entries(params).reduce((final, [query, slug]) => {
                if (
                  translatedSlugs &&
                  translatedSlugs['en'] &&
                  translatedSlugs['en'].find((e) => e.slug_origin === slug)
                ) {
                  const newSlug = translatedSlugs['en'].find(
                    (e) => e.slug_origin === slug
                  );

                  return { ...final, [query]: newSlug?.slug_trans ?? slug };
                }

                return { ...final, [query]: slug };
              }, {})
            : {}
        )
      )}`}
    />
  );

  //@ts-ignore
  if (!!enUS[route]) {
    links.push(
      <link
        key="en-us"
        rel="alternate"
        hrefLang="en-us"
        // en-us is always hardcoded
        //@ts-ignore
        href={`${
          process.env.NEXT_PUBLIC_HOST?.includes('dev')
            ? 'https://dev.b-parts.com'
            : 'https://www.b-parts.com'
        }${removeTrailingSlash(enUS[route])}`}
      />
    );
  }

  return (
    <>
      <link
        rel="canonical"
        href={`${process.env.NEXT_PUBLIC_HOST}${removeTrailingSlash(
          replaceParams(
            // @ts-ignore
            routes[locale],
            params
          )
        )}`}
      ></link>
      {links}
    </>
  );
};

export default useHreflang;
