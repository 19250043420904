import cx from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

import PaymentSvg from './payment';
import ProtectSvg from './protect';
import ShippingSvg from './shipping';

type TVariants = 'PROTECT' | 'SHIPPING' | 'PAYMENT';

const returnIcon = (type: TVariants, className: string, alt: string = '') => {
  switch (type) {
    case 'PROTECT':
      return <ProtectSvg title={alt} className={className} />;
    case 'SHIPPING':
      return <ShippingSvg title={alt} className={className} />;
    case 'PAYMENT':
      return <PaymentSvg title={alt} className={className} />;

    default:
      return null;
  }
};

const SupportBanner: FunctionComponent<{
  alt: string;
  title: ReactNode;
  text: ReactNode;
  variant: TVariants;
  className?: string;
}> = ({ alt, title, text, variant, className }) => (
  <section
    className={
      (cx('w-full text-sm text-center px-4 mb-14 text-neutral-80'), className)
    }
  >
    {returnIcon(variant, 'm-auto h-12', alt)}
    <h2 className="text-2xl font-bold mb-3 mt-6">{title}</h2>
    <p className="text-base font-light">{text}</p>
  </section>
);

export default SupportBanner;
