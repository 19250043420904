import { FunctionComponent } from 'react';

const ProtectSvg: FunctionComponent<{ title?: string; className?: string }> = ({
  title,
  ...props
}) => (
  <svg
    {...props}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M34.2307 15.7692L21.4461 30.5385L15.7691 24.9769M48.896 8.42731C38.5784 6.57654 34.3126 5.20577 24.9999 1C15.6872 5.20577 11.4214 6.57654 1.10375 8.42731C-0.765485 38.0535 23.2207 48.2835 24.9999 49C26.7791 48.2835 50.7653 38.0535 48.896 8.42731Z"
      stroke="#359AD2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProtectSvg;
